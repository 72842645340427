import { reactive, toRefs } from 'vue';
import { vsModal } from '../investments/useInvestmentsModals';

/**
* TODO: Refs should probably get separated to the
*       corresponding hooks, as the state below
*       only obfuscates the code and makes it harder
*       to assemble.
*/
const modalState = reactive({
  machineAdd: false,
  machineEdit: false,
  reportMth: false,
  reportMthEdit: false,
  reportFuel: false,
  equipmentAdd: false,

  machineAssignTool: false,

  /**
   * Resource
   */
  modalCreateResource: vsModal({
    text: {
      title: 'Dodaj zasób',
    },
  }),

  modalEditResource: vsModal({
    text: {
      title: 'Edytuj zasób',
    },
  }),

  /**
   * Insurance
   */
  modalCreateInsurance: vsModal({
    text: {
      title: 'Dodaj dane ubezpieczeniowe',
    },
  }),

  modalEditInsurance: vsModal({
    text: {
      title: 'Aktualizuj dane ubezpieczeniowe',
    },
  }),

  modalPreviewInsurance: vsModal({
    noConfirm: true,
    text: {
      title: 'Szczegóły ubezpieczenia pojazdu',
      cancel: 'Zamknij',
    },
  }),

  /**
   * Service
   */
  modalCreateService: vsModal({
    text: {
      title: 'Utwórz serwis',
    },
  }),

  modalEditService: vsModal({
    text: {
      title: 'Aktualizuj serwis',
    },
  }),

  /**
   * Transport
   */
  modalCreateTransport: vsModal({
    text: {
      title: 'Utwórz zlecenie transportu',
    },
  }),

  modalEditTransport: vsModal({
    text: {
      title: 'Aktualizuj zlecenie transportu',
    },
  }),

  modalAddTransportResource: vsModal({
    text: {
      title: 'Dodaj zasób do zlecenia transportu',
      cancel: 'Zamknij',
      ok: 'Dodaj zasób do listy',
    },
  }),

  modalEditTransportResource: vsModal({
    text: {
      title: 'Aktualizuj transportowany zasób',
      cancel: 'Zamknij',
      ok: 'Aktualizuj zasób',
    },
  }),

  /**
   * Report
   */
  modalResourceQR: vsModal({
    noConfirm: true,
    size: 'md',
    text: {
      title: 'Kod QR dla zasobu',
      cancel: 'Zamknij okno',
    },
  }),

  modalCreateReport: vsModal({
    text: {
      title: 'Dodaj raport zasobu',
    },
  }),
  modalEditReport: vsModal({
    text: {
      title: 'Edytuj raport zasobu',
    },
  }),
  modalAttachResource: vsModal({
    text: {
      title: 'Edytuj przypisane zasoby',
    },
  }),

  /**
   * Protocol
   */
  modalCreateHandoverProtocol: vsModal({
    text: {
      title: 'Protokół odbiorczy',
    },
  }),
  modalCreateReturnProtocol: vsModal({
    text: {
      title: 'Protokół zdawczy',
    },
  }),
});

export default function useBaseModals() {
  return { ...toRefs(modalState) };
}
